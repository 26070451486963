<template>
  <div class="snm">
    <img class="imgs" src="../assets/img01/404.png" alt="" />
    <div class="box">
      <img src="../assets/img01/4041.png" alt="" />
      <div class="text">
        <h2>你的页面不在你走的路上正在玩命导航中</h2>
        <ul class="list">
          <router-link tag="li" to="/industry?id=29"
            >> 产品与解决方案</router-link
          >
          <router-link tag="li" to="/news/company">> 新闻动态</router-link>
          <router-link tag="li" to="/light">> LIGHT</router-link>
          <router-link tag="li" to="/customer">> U+</router-link>
          <router-link tag="li" to="/investor">> 投资者关系</router-link>
          <router-link tag="li" to="/about">> 关于恒生</router-link>
          <router-link tag="li" to="/joinus">> 加入我们</router-link>
        </ul>
        <button @click="btn">返回上一页</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {
    btn() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
@media (min-width: 520px) {
  .snm {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  .imgs {
    width: 1558px;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
  .box {
    width: 1200px;
    display: flex;
    padding-left: 142px;
  }
  .box > img {
    width: 515px;
    height: 315px;
    margin-right: 36px;
  }
  .text {
    width: 264px;
  }
  .text h2 {
    font-size: 24px;
    color: #333333;
    line-height: 33px;
    margin-bottom: 16px;
  }
  .list li {
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 28px;
    cursor: pointer;
  }
  .list li:hover {
    color: #396aff;
  }
  button {
    width: 120px;
    height: 36px;
    background: #396aff;
    border: none;
    color: #fff;
    margin-top: 24px;
    cursor: pointer;
  }
}
</style>